<template>
  <b-card>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <!-- Type Dropdown -->
      <b-form-group class="pr-0 pl-0 col-lg-2 col-md-3">
        <v-select v-model="departmentFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
          placeholder="All Department" :options="departmentOptions" :reduce="(val) => val.id" />
      </b-form-group>
      <b-form-group class="pr-0 pl-0 col-lg-3 col-md-3">
        <v-select v-model="documentTypeFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
          placeholder="All Document Type" :options="documentTypeOptions" :reduce="(val) => val.id" />
      </b-form-group>
      <b-form-group class="pl-0 pr-0">
        <b-form-input v-model="documentNameFilter" placeholder="Document Name" />
      </b-form-group>
      <b-form-group class="pl-0 pr-0">
        <b-form-input v-model="documentNumberFilter" placeholder="Document No" />
      </b-form-group>

      <b-form-group label-sr-only label="Buttons" class="ml-1 pr-0 pl-0">
        <b-button type="submit" variant="outline-success" class="mr-1" @click.prevent="searchFunc">
          Search
        </b-button>

      </b-form-group>
    </div>
    <div class="custom-search d-flex">
      <b-form-group>
        <b-button variant="outline-primary" @click.prevent="printList">
          Print List
        </b-button>
      </b-form-group>
    </div>
    <!-- table -->
    <div v-if="isShow" class="vueTable">
      <vue-good-table :columns="columns" :rows="rows" :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }" :line-numbers="true" theme="my-theme">
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span class="d-flex">
              <b-button class="mr-1 px-1" variant="outline-warning" @click="viewDocument(props.row)">View</b-button>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select v-model="pageLength" :options="['10', '20', '30', '40', '50']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })
                " />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <Documents v-if="isPrintDocument" ref="docRef" :document-data="documentData" />

    <!--    :invoice-data="invoiceData"-->
    <!--    :student-info="studentInfo"-->
  </b-card>
</template>
<script>
import {
  BCard,
  BPagination,
  BFormInput,
  // BFormRadio,
  BFormGroup,
  BFormSelect,
  // BRow,
  // BCol,
  VBModal,
  BButton,
  // BFormRadioGroup,
  // BFormInvalidFeedback,
  // BFormValidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Documents from '@/views/resource-sharing/components/Documents.vue'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BFormInput,
    // BFormRadio,
    BFormGroup,
    BFormSelect,
    // BRow,
    // BCol,
    BButton,
    // BFormRadioGroup,
    // BFormInvalidFeedback,
    // BFormValidFeedback,
    Documents,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,

      columns: [
        {
          label: 'Document Number',
          field: 'document_number',
        },
        {
          label: 'Document Name',
          field: 'document_name',
        },
        {
          label: 'Document tpye',
          field: 'document_type.name',
        },
        {
          label: 'Department',
          field: 'departmentNames',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],

      departmentFilter: null,
      departmentFilterState: null,
      departmentOptions: [],

      documentTypeFilter: null,
      documentTypeFilterState: null,
      documentTypeOptions: [],

      documentNameFilter: null,
      documentNameFilterState: null,
      documentNumberFilter: null,
      documentNumberFilterState: null,

      isShow: false,
      isPrintDocument: true,

      documentData: {},
    }
  },
  mounted() {
    this.getDocumentTypes()
    this.getDepartments()
  },
  methods: {
    /*
    * get data from apis
    * */
    getDocumentTypes() {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/document-print/document-type`, header)
        .then(res => {
          if (res.data.status) {
            this.documentTypeOptions = res.data.data
          } else {
            FemsToastrService.error(res?.data?.message)
            this.documentTypeOptions = []
          }
        })
    },

    getDepartments() {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/document-print/get-department`, header)
        .then(res => {
          if (res.data.status) {
            this.departmentOptions = res.data.data
          } else {
            this.departmentOptions = []
          }
        })
    },
    /*
     * get data from apis
     * */

    /**
     * search function with validation function
     * */
    validationSearch() {
      this.departmentFilterState = null
      this.documentTypeFilterState = null
      this.documentNameFilterState = null
      this.documentNumberFilterState = null

      if (!this.departmentFilter && !this.documentTypeFilter && !this.documentNameFilter && !this.documentNumberFilter) {
        FemsToastrService.error('please select any one item or insert data for searching')
        return false
      }
      return true
    },
    searchFunc() {
      // if (!this.validationSearch()) {
      //   this.isShow = false
      //   return
      // }
      const params = {
        departmentId: this.departmentFilter,
        documentType: this.documentTypeFilter,
        documentName: this.documentNameFilter,
        documentNo: this.documentNumberFilter,
      }
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.post(`${window.apiUrl}resource-sharing/document-print/read`, params, header)
        .then(res => {
          if (res.data.status) {
            this.isShow = true
            this.rows = res.data.data
          } else {
            this.isShow = false
            this.rows = []
          }
        })
    },
    /**
     * search function with validation function
     * */

    viewDocument(rowData) {
      window.open(`${window.apiUrlForDocumentStorage}storage/${rowData.document_link}`, '_blank')
    },

    printList() {
      const params = {
        departmentId: this.departmentFilter,
        documentType: this.documentTypeFilter,
        documentName: this.documentNameFilter,
        documentNo: this.documentNumberFilter,
      }
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.post(`${window.apiUrl}resource-sharing/document-print/printList`, params, header)
        .then(res => {
          if (res.data.status) {
            this.isPrintDocument = true
            this.documentData = res.data.data
            this.$nextTick(() => {
              this.$refs.docRef.printDocument()
            })
          }
        })
    },

  },
}
</script>
