<template>
  <section
    v-if="documentData && Object.keys(documentData).length >= 1"
    class="invoice-preview-wrapper"
  >
    <div id="exportArea">
      <div class="page-break Justify-content-center">
        <div class="document-head text-center">
          <div style="font-size: 26px">
            Oxford International School
          </div>
          <div style="border: 1px solid black; margin: 10px 205px;">
            House # 34, Road # 27(Old), New 16, Dhanmondi R/A, Dhaka -1209
          </div>
          <div style="font-size: 26px">
            Internal Resource List
          </div>
        </div>
        <div class="body mt-4">
          <div
            v-for="(data) in documentData"
            :key="data.id"
            class="mb-2"
          >
            <h3> Department Name : {{ data.departmentName }}</h3>
            <table id="printTable">
              <thead>
                <tr class="left-padding">
                  <th
                    width="180px"
                    class="left-padding"
                  >
                    Document Number
                  </th>
                  <th class="left-padding">
                    Document Name
                  </th>
                  <th
                    width="150px"
                    class="left-padding"
                  >
                    Document Type
                  </th>
                  <th
                    width="240px"
                    class="left-padding"
                  >
                    Upload At
                  </th>
                  <th
                    width="240px"
                    class="left-padding"
                  >
                    Group Wise Access
                  </th>
                  <th
                    width="240px"
                    class="left-padding"
                  >
                    Individual Access
                  </th>
                </tr>
              </thead>
              <thead>
                <tr
                  v-for="item in data.documentData"
                  :key="item.id"
                  class="left-padding"
                >
                  <td class="left-padding">
                    {{ item.form_number }}
                  </td>
                  <td class="left-padding">
                    {{ item.document_name }}
                  </td>
                  <td class="left-padding">
                    <p
                      v-if="item.document_type && item.document_type.name"
                    >
                      {{ item.document_type.name }}
                    </p>
                  </td>
                  <td class="left-padding">
                    {{ item.created_at }}
                  </td>
                  <td class="left-padding">
                    <div
                      v-if="item.group_wise_access && item.group_wise_access.name_list && item.group_wise_access.name_list.length > 0"
                    >
                      <p
                        v-for="(data , index) in item.group_wise_access.name_list"
                        :key="index"
                      >
                        <span>{{ index+1 }}.&nbsp;</span>
                        <span v-if="data.campus_names && (data.campus_names !== null && data.campus_names !== '') ">
                          Camups - {{ data.campus_names }}
                        </span>
                        <span v-if="data.department_names && (data.department_names !== null && data.department_names !== '') ">
                          ;&nbsp;Department - {{ data.department_names }}
                        </span>
                        <span v-if="data.designation_names && (data.designation_names !== null && data.designation_names !== '') ">
                          ;&nbsp;Designation - {{ data.designation_names }}
                        </span>
                      </p>
                    </div>
                  </td>
                  <td class="left-padding">
                    <p
                      v-if="item.individual_access && item.individual_access.name"
                    >
                      {{ item.individual_access.name }}
                    </p>

                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {
  // BButton,
  // BCard, BFormGroup, BFormInput, BFormSelect, BPagination,
  // BRow,
  // BCol,
  VBToggle,
  // BImg,
} from 'bootstrap-vue'
// import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import PDFGenerator from '@/@service/utils/PDFGenerator'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    // BRow,
    // BCol,
    // BImg,
    // VueGoodTable,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    documentData: Object,

  },
  data() {
    return {
      columns: [
        {
          label: 'Document Number',
          field: 'form_number',
        },
        {
          label: 'Document Name',
          field: 'document_name',
        },
        {
          label: 'Document tpye',
          field: 'document_type.name',
        },
        {
          label: 'Upload Date',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
        },
      ],
      rows: [],

      cmsData: JSON.parse(LocalStorageService.get('pageData')) || '',
      totalAmount: 0,
      invoiceCopies: ['Bank', 'School', 'Student'],

    }
  },

  created() {
    if (!this.cmsData) {
      LocalStorageService.clear()
      this.$router.push({ name: 'auth-login' })
    }
  },
  methods: {
    // PDF generator using node package jspdf
    generatePdf() {
      const content = document.getElementById('exportArea')
      content.classList.remove('d-none')
      const paperOrientation = 'l'
      const paperSize = 'A4'
      PDFGenerator.generatePDF(content, paperOrientation, paperSize)
      content.classList.add('d-none')
    },
    // Print invoice using window.print() method javascript default
    printDocument() {
      const pdfTitle = document.title
      const content = document.getElementById('exportArea')
      content.classList.remove('d-none')
      document.title = 'DocumentList'
      window.print()
      document.title = pdfTitle
      content.classList.add('d-none')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  @page {
    size: landscape;
    //margin: 0;
    margin: 10mm 5mm 5mm 10mm;
  }
  // Global Styles
  body {
    background-color: transparent !important;
    //margin: 25mm 25mm 25mm 25mm;
  }
  .page-break {
    page-break-after: always;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .btn-scroll-to-top {
    display: none;
  }
  .custom-search{
    display: none !important;
  }
  .vueTable{
    display: none !important;
  }
  #printTable {
    border: 1px solid black;
  }
  #printTable td,
  th {
    border: 1px solid black;
  }
  #printTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 5px;
  }
  .left-padding{
    padding-left: 10px;
  }
}
</style>
